import { useState, useCallback, useEffect } from "react";
import Cookies from "js-cookie";
import { navigate } from "gatsby-link";

export const useAuth = () => {
  const isSsr = typeof window === "undefined";
  const localData = !isSsr ? localStorage.getItem("userData") : null;
  const userToken = localData ? JSON.parse(localData).token : null;
  const [mentor, setMentor] = useState<boolean>(false);
  const [mentorData, setMentorData] = useState<any>(null);
  const [superUser, setSuperUser] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(userToken);
  const [user, setuser] = useState<any>(null);
  const [userNotFound, setUserNotFound] = useState<boolean>(false);

  const login = useCallback((mentor, mentorData, token, user) => {
    setToken(token);
    setMentor(mentor);
    setMentorData(mentorData);
    setuser(user);
    const userDataJson = JSON.stringify({
      mentor,
      mentorData,
      user,
    });
    localStorage.setItem("userData", userDataJson);
    Cookies.set("token", token);
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setMentor(false);
    setMentorData(null);
    setuser(null);
    localStorage.removeItem("userData");
    Cookies.set("token", null);
    navigate("/");
  }, []);

  useEffect(() => {
    const localStorageData = localStorage.getItem("userData");
    const getToken = Cookies.get("token");
    if (localStorageData && getToken) {
      const storedData = JSON.parse(localStorageData);
      login(
        storedData.mentor,
        storedData.mentorData,
        getToken,
        storedData.user
      );
    }
  }, [login]);
  return { token, login, logout, mentor, mentorData, user };
};
